import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
const arrowIcon = '/static/landslide/icons/arrow.png'

function PageHeaderGoBack({ goBack, title, setNavTab }) {
  return (
    <div id="join-pages-header">
      {setNavTab ? (
        <img id="gtm-page-header-go-back" src={arrowIcon} onClick={() => setNavTab('voter-info-profile')} alt="go back" />
      ) : goBack ? (
        <img id="gtm-page-header-go-back" src={arrowIcon} onClick={() => goBack((step) => step - 1)} alt="go back" />
      ) : (
        <Link href="/">
          <img src={arrowIcon} alt="go back" />
        </Link>
      )}
      <h3>{title}</h3>
    </div>
  )
}

PageHeaderGoBack.defaultProps = {
  goBack: null,
}

PageHeaderGoBack.propTypes = {
  goBack: PropTypes.func,
  setNavTab: PropTypes.func,
  title: PropTypes.string.isRequired,
}

export default PageHeaderGoBack
